import React, { useState } from "react";
import { sdk } from "../services";
import { useCurrentUser } from "../contexts/user";
import { Link } from "react-router-dom";

export function Login() {
  const {currentUser } = useCurrentUser();
const [email, setEmail] = useState('');
const emailChangeHandler = (e) => setEmail(e.target.value);
const sendLinkButtonHandler = () => {
sdk.sendLoginLink(email).then((response) => {
  console.log(response)
}).catch(error => {
  console.log({error})
})
};

  return (
    <div>
      {
        currentUser ? (
          <>
          <h1>You are already logged in</h1>
          <Link to={'/'} >Go to Home</Link>
          </>
        ) : (
         <>
          <input type="email" value={email} onChange={emailChangeHandler} />
    <button onClick={sendLinkButtonHandler}>Send Login Link</button>
         </>
        )
      }

    </div>
  );
}
