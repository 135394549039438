import { useLocation } from "react-router-dom";

export function AccessDenied() {
    const {state} = useLocation();
    const {loginHash} = state || {};
    return (
        
        loginHash ? (<>
        <h1>You provided an invalid login hash!</h1>
        <p>Hash provided: {loginHash}</p>
        </>) : (
           <h1> You do not have access to this page</h1>
        )
    );
}