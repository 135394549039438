import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sdk } from "../services";
import {useCurrentUser} from "../contexts/user";

function reducer(state, action) {
  switch (action.type) {
    case 'START':
      return {
        error: false,
        verifying: true,
      }
    case 'ERROR':
      return {
        error: action.payload,
        verifying: false,
      }
    default:
        return {}

  }
}

export function TokenVerify() {
  const { currentUser, setCurrentUser } = useCurrentUser();

  const [operation, dispatch] = useReducer(reducer, {});
  console.log({operation})
  const {error, verifying} = operation;

  const {token} = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (token && !currentUser) {
      dispatch({type: 'START'});
      sdk
        .verifyUser(token)
        .then((response) => {
          setCurrentUser(response);
          navigate('/profile');
        })
        .catch((error) => {
          setCurrentUser(null);
          dispatch({type: 'ERROR', payload: error.message});
          console.log({error});
        });
    }
  }, [token, navigate, setCurrentUser, currentUser, dispatch]);

  if (currentUser) {
    navigate('/profile')
  }

console.log({verifying, error});
if (verifying) {
  return <h1>Verifying User</h1>
}

if(error) {
  return <h1>Error while verifying user: {error}</h1>
}
  return (
    null
  );
}
