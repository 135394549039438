import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { sdk } from "../services";

const userContext = createContext();

const { Provider } = userContext;

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    return sdk.logoutUser().then((res) => {
      console.log('User logged out')
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      setCurrentUser(null);
      navigate('/logged-out', {
        state: {
          currentUser
        }
      })
    })
  }, [currentUser, navigate]);

  useEffect(() => {
    sdk
    .getCurrentUser()
    .then((response) => {
      setCurrentUser(response.user);
    })
    .catch((error) => {
      console.log("current user", { error });
    });
  }, []);

  const value = useMemo(
    () => ({ currentUser, setCurrentUser, logoutUser }),
    [currentUser, logoutUser]
  );
  return <Provider value={value}>{children}</Provider>;
}

export function useCurrentUser() {
  return useContext(userContext);
}
