import { Outlet } from "react-router-dom";
import { UserProvider } from "../contexts/user";
import { Footer } from "./Footer";
import { Header } from "./Header";

export function Layout() {
  return (
    <UserProvider>
      <Header />
      <Outlet />
      <Footer />
    </UserProvider>
  );
}
