import { Link } from "react-router-dom";
import { useCurrentUser } from "../contexts/user";
import { sdk } from "../services";

export function Header() {
    const {currentUser, logoutUser} = useCurrentUser();
    console.log({ currentUser });

    return (
        <header>
            <nav>
                <h1>This is Header</h1>
                {currentUser ? (
                    <>
                    <p>You are logged in as {currentUser.email}</p>
                    <button onClick={logoutUser}>Logout</button>
                    </>
                ) : (
                    <p>You are not logged In</p>
                )}
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/profile">Profile</Link>
                    </li>
                    <li>
                        <Link to="/login">Login</Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}