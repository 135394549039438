import { Navigate } from "react-router-dom";
import { useCurrentUser } from "../contexts/user";

export function PrivateRoute({ children }) {
  const {currentUser} = useCurrentUser();

  if(!currentUser) {
    // redirect
   return <Navigate to={'/'} />
  }

  return children;
}
