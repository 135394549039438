class SDK {
  #host = process.env.REACT_APP_API_DOMAIN;
  constructor() {}

  #makeGET(url) {
    const endpoint = `${this.#host}${url}`;
    return fetch(endpoint, {
      method: "GET",
      credentials: "include",
      redirect: 'follow'
    }).then((response) => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    });
  }
  #makePOST(url, body) {
    const endpoint = `${this.#host}${url}`;
    return fetch(endpoint, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
    }).then((response) => {
      if(!response.ok) {
        throw new Error(response.statusText)
      }
      return true;
    });
  }

  getCurrentUser() {
    return this.#makeGET("/auth/session");
  }

  verifyUser(userToken) {
    return this.#makePOST("/auth/verify", { userToken });
  }
  logoutUser() {
    return this.#makePOST("/auth/logout");
  }

  sendLoginLink(email) {
    return this.#makePOST("/auth/login", {email});
  }

  listAllusers() {
    return this.#makeGET("/users");
  }

  fetchUserLinks(username) {
    return this.#makeGET("/users/links/" + username);
  }

}

export const sdk = new SDK();
