import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { sdk } from "../services";

function reducer(state, action) {
  switch (action.type) {
    case 'START':
      return {
        loading: true,
      }
    case 'COMPLETED':
      return {
        loading: false,
        error: action.payload?.error,
        links: action.payload?.links,
      }
    default:
      break;
  }
}

export function UserSocialPage() {

  const {username} = useParams();

  const [store, dispatch] = useReducer(reducer, {});

  const {loading, error, links} = store;

  useEffect(() => {
    dispatch({type: 'START'})
    sdk.fetchUserLinks(username)
      .then((response) => {
      console.log(response);
      dispatch({
        type: 'COMPLETED',
        payload: {links: response}
      })
    }).catch(error => {
      dispatch({
        type: 'COMPLETED',
        payload: {error}
      })
    })
  }, [dispatch, username]);

  if (loading) {
    return <h1>Fetching user links for ${username}</h1>
  }

  if (error) {
    return <h1>there was some error fetching links for {username}</h1>
  }

  return (
    <div>
      <h1>Social page for {username} </h1>
      {
       links?.length ? links.map((link) => (
          <li key={link.id}>
          <img src={link.logo} width={"100px"} alt={link.label} height={"100px"} />
          <a href={link.url} >{link.label}</a>
          </li>
        )) : <h2>No Links found!</h2>
      }
    </div>
  );
}
