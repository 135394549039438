import React from "react";

export function Home() {

  return (
    <div>
      <h1>Hello Home</h1>
    </div>
  );
}
