import { Outlet, createBrowserRouter } from "react-router-dom";
import { Layout } from "./blocks/Layout";
import { PrivateRoute } from "./HOC/PrivateRoute";
import { AccessDenied } from "./pages/401";
import { PageNotFound } from "./pages/404";
import { Home } from "./pages/home";
import { TokenVerify } from "./pages/token-verify";
import { LoggedOut } from "./pages/logged-out";
import { Profile } from "./pages/private/profile";
import { Login } from "./pages/login";
import { UserSocialPage } from "./pages/user-social-page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: "access-denied",
        element: <AccessDenied />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "token/:token",
        element: <TokenVerify />,
      },
      {
        path: "logged-out",
        element: <LoggedOut />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "u",
    element: <Outlet />,
    children: [
      {
        path: ':username',
        element: <UserSocialPage />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },

]);
