import { Link, useLocation } from "react-router-dom";

export function LoggedOut() {
    const {state} = useLocation();
    const {currentUser} = state || {};
    console.log({currentUser})
    return (
        
        currentUser ? (<>
        <h1>Dear {currentUser.email}, You have been logged out</h1>
        <Link to={"/"}>HomePage</Link>
        </>) : (
           <h1> You are already logged out</h1>
        )
    );
}